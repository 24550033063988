import { Box, ButtonBase, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom"
import DB, { DBResult } from "../utils/database";
import { Division, ICardInfo, ID, IDevice, IDualMeet_DB, IDualMeetBout } from "../utils/types";
import useDatabase from "../hooks/useDatabase";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

import "./Viewer.css";
import { CommonLoading } from "../components/Loading/Loading";
import { idxFromWeaponHs, weaponFromIdxHs } from "../utils/helpers";
import ErrorPage from "./Error/Error";

type CardProps = {
    color: "yellow" | "black" | "transparent";
    num?: never;
} | {
    color: "red";
    num: number;
}

const Card = ({ color, num }: CardProps) => {
    return <Box className="boutCard" sx={{ backgroundColor: color }}>
        { num }
    </Box>
}

const CardsList = ({ cards }: { cards: ICardInfo }) => {
    return <>
        <Card color="transparent" />
        { cards.yellow ? <Card color="yellow" /> : <Card color="transparent" /> }
        { cards.red ? <Card color="red" num={cards.red} /> : <Card color="transparent" /> }
        { cards.black ? <Card color="black" /> : <Card color="transparent" /> }
    </>
}

const Viewer = () => {
    const { id } = useParams();
    const DB = useDatabase();
    const [data, setData] = useState<IDevice | null>(null);
    const [meet, setMeet] = useState<IDualMeet_DB | null>(null);
    const [bout, setBout] = useState<IDualMeetBout | null>(null);
    const [boutChangeLoading, setBoutChangeLoading] = useState(false);

    const handleData = (result: DBResult<IDevice>) => {
        if (result.status === "fail") return;
        setData(result.data);
    }

    const handleBout = (result: DBResult<IDualMeetBout>) => {
        if (result.status === "fail") return;
        setBout(result.data);
    }

    useEffect(() => {
        if (!id) return;

        DB.getDevice(id, handleData).then(result => {
            if (result.status === "fail") return;
            const device = result.data;
            if (device?.meet) DB.getMeet(device.meet, device.division).then((result: DBResult<IDualMeet_DB>) => {
                if (result.status === "fail") return;
                setMeet(result.data);
            });
        });

        return () => {
            DB.stopListeningDevice(id, handleData);
        }
    }, [id]);

    useEffect(() => {
        if (data?.bout && data?.division) {
            DB.getBout(data.bout, data.division, handleBout);
        }

        return () => {
            if (data?.bout) {
                DB.stopListeningBout(data.bout, handleBout);
            }
        }
    }, [data?.bout, data?.division]);

    if (!id) {
        return <ErrorPage code={404} message="The device ID is invalid." />
    }

    if (!bout) {
        return <Box sx={{ height: "100%", width: "100%", background: "black" }}>
            <CommonLoading />
        </Box>
    }

    const previousBout = async () => {
        if (!data?.weapon || boutChangeLoading) return;

        setBoutChangeLoading(true);
        if (data.division === Division.NCAA) {
            if (bout.order <= 0) return;

            await DB.setDeviceBoutIdx(id, bout.order - 1, true);
        } else if (data.division === Division.NJSIAA) {
            if (bout.order <= 0 && bout.weapon === "Sabre") return;

            const currentIdx = idxFromWeaponHs(data.weapon, bout.order);
            if (currentIdx <= 0) return;
            const nextData = weaponFromIdxHs(currentIdx - 1);
            if (nextData.weapon !== data.weapon) {
                await DB.setDeviceWeapon(id, nextData.weapon, true);
            }
            await DB.setDeviceBoutIdx(id, nextData.bout, true);
        }
        setBoutChangeLoading(false);
    }

    const nextBout = async () => {
        if (!data?.weapon || boutChangeLoading) return;

        setBoutChangeLoading(true);
        if (data.division === Division.NCAA) {
            if (bout.order >= 8) return;

            await DB.setDeviceBoutIdx(id, bout.order + 1, true);
        } else if (data.division === Division.NJSIAA) {
            if (bout.order >= 8 && bout.weapon === "Epee") return;

            const currentIdx = idxFromWeaponHs(data.weapon, bout.order);
            if (currentIdx >= 26) return;
            const nextData = weaponFromIdxHs(currentIdx + 1);
            if (nextData.weapon !== data.weapon) {
                await DB.setDeviceWeapon(id, nextData.weapon, true);
            }
            await DB.setDeviceBoutIdx(id, nextData.bout, true);
        }
        setBoutChangeLoading(false);
    }

    const leftFencer = !bout.switchedSides ? bout.fencer1 : bout.fencer2;
    const rightFencer = bout.switchedSides ? bout.fencer1 : bout.fencer2;

    const canGoBack = boutChangeLoading
        ? false
        : data?.division === Division.NCAA
            ? bout.order <= 0
            : bout.order <= 0 && data?.weapon === "Sabre";
    
    const canGoForward = boutChangeLoading
        ? false
        : data?.division === Division.NCAA
            ? bout.order >= 8
            : bout.order >= 8 && data?.weapon === "Epee";

    return <Box sx={{ height: "100%", width: "100%", background: "black" }}>
        <Box sx={{ width: "100%", display: "flex" }}>
            <Box sx={{ width: "calc(50% - 30px)", marginRight: "30px", overflowX: "hidden" }}>
                <Typography sx={{ overflowX: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", color: "red", fontSize: 72, margin: "0 0 0 10px" }}>
                    <span style={{ textTransform: "uppercase" }}>{ leftFencer.fencerInfo.lastName }</span> { leftFencer.fencerInfo.firstName }
                </Typography>
                <Typography sx={{ color: "white", fontSize: 160, textAlign: "right", marginRight: "30px" }}>
                    { leftFencer.score }
                </Typography>
            </Box>
            <Box sx={{ width: "calc(50% - 30px)", marginLeft: "30px", overflowX: "hidden" }}>
                <Typography sx={{ overflowX: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", color: "limegreen", fontSize: 72, textAlign: "right", margin: "0 10px 0 0" }}>
                    <span style={{ textTransform: "uppercase" }}>{ rightFencer.fencerInfo.lastName }</span> { rightFencer.fencerInfo.firstName }
                </Typography>
                <Typography sx={{ color: "white", fontSize: 160, textAlign: "left", marginLeft: "30px" }}>
                    { rightFencer.score }
                </Typography>
            </Box>
        </Box>
        <Box className="cardsListContainer" sx={{ left: 0 }}>
            <CardsList cards={bout.fencer1.cardInfo} />
        </Box>
        <Box className="cardsListContainer" sx={{ right: 0, flexDirection: "row-reverse" }}>
            <CardsList cards={bout.fencer2.cardInfo} />
        </Box>
        <Box sx={{ position: "absolute", bottom: 0, borderTop: "1px solid #999", width: "100%", display: "flex", justifyContent: "center", height: "150px" }}>
            <ButtonBase className="boutButtonContainer" disabled={ canGoBack } onClick={ previousBout }>
                <Box className="boutButton">
                    <ChevronLeft sx={{ color: "white", fontSize: 48 }} />
                    <Typography className="boutButtonText" sx={{ color: "white", fontSize: 48 }}>
                        Previous
                    </Typography>
                </Box>
            </ButtonBase>
            <Typography sx={{ color: "white", fontSize: 96, flexGrow: 0, textAlign: "center" }}>
                { bout.weapon } #{ bout.order + 1 }
            </Typography>
            <ButtonBase className="boutButtonContainer" disabled={ canGoForward } onClick={ nextBout }>
                <Box className="boutButton">
                    <Typography className="boutButtonText" sx={{ color: "white", fontSize: 48 }}>
                        Next
                    </Typography>
                    <ChevronRight sx={{ color: "white", fontSize: 48 }} />
                </Box>
            </ButtonBase>
        </Box>
    </Box>
}

export default Viewer;