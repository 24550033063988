import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    Checkbox,
    Chip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormHelperText,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Paper,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {
    Division,
    ICollegeEvent,
    IDevice,
    IDeviceGroup,
    IDeviceInvite,
    IDualMeet_DB,
    IUser,
    UserFlag,
    Weapon,
} from "../../utils/types";
import useDatabase from "../../hooks/useDatabase";
import { DBResult } from "../../utils/database";
import { Add, ArrowBack, ArrowForward, DeleteOutline, PersonAdd, Refresh, Search, Upload } from "@mui/icons-material";
import { genRandomColor } from "../../utils/helpers";
import { useAppSelector } from "../../utils/store";
import GroupNameText from "../../components/GroupNameText";
import ErrorPage from "../Error/Error";
import { CommonLoading } from "../../components/Loading/Loading";
import Device from "../../components/Device";
import InviteCode from "../../components/InviteCode";


interface ChooseEventProps {
    events: Record<string, ICollegeEvent>;
    chooseEvent: (eventId: string) => void;
    close: () => void;
}

const ChooseEvent = ({ events, chooseEvent, close }: ChooseEventProps) => {
    const [filter, setFilter] = useState("");
    const allEvents = useMemo(() => Object.values(events).toSorted((a, b) => b.startedAt - a.startedAt), [events]);

    const eventOptions = filter ? allEvents.filter(l => l.name.toLowerCase().includes(filter.toLowerCase())) : [];

    const errorText = !filter ? "Type something to begin searching..." : !eventOptions.length ? "No events found..." : "";

    return (
        <Box>
            <DialogTitle variant="h4" sx={{ fontFamily: "Lexend Deca" }}>
                Choose event
            </DialogTitle>
            <DialogContent>
                <Box sx={{ width: "600px" }}>
                    <TextField
                        fullWidth
                        value={filter}
                        onChange={e => setFilter(e.target.value)}
                        label="Event name"
                        placeholder="Start typing to find an event..."
                        sx={{ marginTop: "10px", marginBottom: "30px" }}
                    />
                    <Grid container spacing={2} sx={{ padding: "0 15px 15px 15px", maxHeight: "400px", overflowY: "auto" }}>
                        { eventOptions.map(l => <Grid key={`eventOption${l.id}`} item xs={6} md={4}>
                            <Card>
                                <CardActionArea onClick={ () => chooseEvent(l.id) }>
                                    <CardContent>
                                        <Typography>{ l.name }</Typography>
                                        <Typography variant="caption" sx={{ color: "#444" }}>{ new Date(l.startedAt).toLocaleDateString("en-US") }</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid> ) }
                    </Grid>
                    { errorText ? <Box sx={{ width: "100%", height: 60, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography sx={{ color: "#999", fontFamily: "Lexend Deca", fontSize: 22 }}>{errorText}</Typography>
                    </Box> : null }
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Close</Button>
            </DialogActions>
        </Box>
    );
};

interface ChooseDeviceProps {
    devices: Record<string, IDevice>;
    chooseDevice: (deviceId: string) => void;
    close: () => void;
}

const ChooseDevice = ({ devices, chooseDevice, close }: ChooseDeviceProps) => {
    const [filter, setFilter] = useState("");
    const allDevices = useMemo(() => Object.values(devices), [devices]);

    const deviceOptions = allDevices.filter(l => l.name.toLowerCase().includes(filter.toLowerCase()));

    const errorText = !deviceOptions.length ? "No devices found..." : "";

    return (
        <Box>
            <DialogTitle variant="h4" sx={{ fontFamily: "Lexend Deca" }}>
                Add device
            </DialogTitle>
            <DialogContent>
                <Box sx={{ width: "600px" }}>
                    <TextField
                        fullWidth
                        value={filter}
                        onChange={e => setFilter(e.target.value)}
                        label="Device name"
                        placeholder="Start typing to find a device..."
                        sx={{ marginTop: "10px", marginBottom: "30px" }}
                    />
                    <Grid container spacing={2} sx={{ padding: "0 15px 15px 15px", maxHeight: "400px", overflowY: "auto" }}>
                        { deviceOptions.map(l => <Grid key={`deviceOption${l.id}`} item xs={6} md={4}>
                            <Card>
                                <CardActionArea onClick={ () => chooseDevice(l.id) }>
                                    <CardContent>
                                        <Typography>{ l.name }</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid> ) }
                    </Grid>
                    { errorText ? <Box sx={{ width: "100%", height: 60, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography sx={{ color: "#999", fontFamily: "Lexend Deca", fontSize: 22 }}>{errorText}</Typography>
                    </Box> : null }
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Close</Button>
            </DialogActions>
        </Box>
    );
};

interface CreateGroupProps {
    createGroup: (name: string, color: string) => void;
    close: () => void;
}

const CreateGroupModal = ({ createGroup, close }: CreateGroupProps) => {
    const [name, setName] = useState("");
    const [color, setColor] = useState(genRandomColor());

    const newColor = () => setColor(genRandomColor());

    const submit = () => {
        if (!name || !color) return;
        createGroup(name, color);
    }

    return <Box>
        <DialogTitle variant="h4" sx={{ fontFamily: "Lexend Deca" }}>
            Create Group
        </DialogTitle>
        <DialogContent>
            <Box>
                <TextField
                    required
                    autoFocus
                    value={name}
                    onChange={e => setName(e.target.value)}
                    label="Group name"
                    placeholder="Pick a short name..."
                    sx={{ width: "100%", mt: "5px", mb: "10px" }}
                />
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography>Group color:</Typography>
                    <Box sx={{ backgroundColor: color, borderRadius: 2, width: 30, height: 30, mx: 1 }}></Box>
                    <Button startIcon={<Refresh />} onClick={newColor}>New color</Button>
                </Box>
            </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={close}>Close</Button>
            <Button onClick={submit} disabled={!name || !color}>Create</Button>
        </DialogActions>
    </Box>
}

interface InviteUserModalProps {
    devices: Record<string, IDevice>;
    submitDevices: (devices: string[]) => void;
}

const InviteUserModal = ({ devices, submitDevices }: InviteUserModalProps) => {
    const [checked, setChecked] = useState<string[]>([]);

    const handleToggle = (id: string) => {
        setChecked(current => {
            if (current.includes(id)) {
                const clone = [...current];
                clone.splice(clone.indexOf(id), 1);
                return clone;
            } else {
                return [...current, id];
            }
        });
    };

    return <Box>
        <DialogTitle>Invite User</DialogTitle>
        <DialogContent sx={{ pb: 0 }}>
            <DialogContentText>Choose which devices you want this user to be invited to manage.</DialogContentText>
            <List>
                { Object.values(devices).map(device => {
                    const labelId = `checkbox-list-label-${device.id}`;

                    return <ListItem key={`deviceInInviteList${device.id}`} disablePadding>
                        <ListItemButton
                            role={undefined}
                            onClick={() => handleToggle(device.id)}
                            dense
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={checked.includes(device.id)}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={device.name} />
                        </ListItemButton>
                    </ListItem>
                }) }
            </List>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => submitDevices(checked)}>Submit</Button>
        </DialogActions>
    </Box>
}

interface InviteListModalProps {
    devices: Record<string, IDevice>;
    invites: Record<string, IDeviceInvite>;
    administrators: Map<IUser, string[]>;
    close: () => void;
}

const InviteListModal = ({ invites, administrators, devices, close }: InviteListModalProps) => {
    const DB = useDatabase();
    const userId = useAppSelector(s => s.user.userInfo?.id || "");
    const [creatingInvite, setCreatingInvite] = useState(false);

    const [codeError, setCodeError] = useState(false);
    const [code, setCode] = useState("");
    const [joinError, setJoinError] = useState("");

    const checkCodeError = () => setCodeError(!(code.length === 6 && code.match(/^[a-z0-9]+$/i)));
    
    const createInviteFromDevices = (devices: string[]) => {
        DB.inviteAdmin(devices);
        setCreatingInvite(false);
    }
    
    const deleteInvite = (id: string) => {
        DB.deleteInvite(id);
    }

    const removeAdministrator = (id: string) => {
        DB.removeUserFromDevices(id, Object.keys(devices));
    }

    const useInviteCode = async () => {
        const succeeded = await DB.joinTeamUsingInvite(code);
        if (succeeded.status === "success") {
            const userResult = await DB.getCurrentUserInfo();
            if (userResult.status === "fail") {
                return setJoinError(userResult.data);
            } else {
                setCode("");
                setJoinError("");
                close();
            }
        } else {
            setJoinError(succeeded.data);
        }
    };

    const userCanInvite = [...administrators.keys()].some(l => l?.id === userId || "");

    return <Box>
        <DialogTitle variant="h4" sx={{ fontFamily: "Lexend Deca" }}>
            Invites
        </DialogTitle>
        <DialogContent sx={{ pb: 0 }}>
            <DialogContentText>
                If you have an invite code, please enter it here.
            </DialogContentText>
            <Box sx={{ display: "flex", alignItems: "start" }}>
                <TextField
                    error={codeError}
                    helperText={codeError && "Invite codes are 6 characters long containing letters and numbers only."}
                    onBlur={checkCodeError}
                    value={code}
                    onChange={e => setCode(e.target.value)}
                    label="Invite code"
                    autoFocus
                    required
                    sx={{ margin: "10px 0", width: 300 }}
                />
                <Button variant="contained" sx={{ ml: 1, mt: 2.25 }} onClick={useInviteCode}>Join</Button>
            </Box>
            {Boolean(joinError) && <FormHelperText sx={{ color: "#d32f2f", textAlign: "left", mt: 0 }}>{joinError}</FormHelperText>}
            {userCanInvite && <>
                <Divider  sx={{ marginTop: 2 }} />
                <DialogContentText sx={{ marginTop: 2 }}>
                    Create an invite code to add other people to manage your autoscorers.
                    The person you want to invite must have an account with Touch by Touch to accept the invite.
                </DialogContentText>
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 10
                    }}
                >
                    <Box style={{ width: "49%" }}>
                        <Typography variant="h5" sx={{ marginBottom: "5px" }}>
                            Invite Managers
                        </Typography>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "center", margin: "10px 0" }}>
                            <Button variant="contained" onClick={() => setCreatingInvite(true)}>
                                Create invite code
                            </Button>
                        </Box>
                        <Stack>
                            {Object.values(invites).map(l => (
                                <InviteCode key={`inviteCode${l.id}`} data={l} deleteInvite={() => deleteInvite(l.id)} />
                            ))}
                        </Stack>
                    </Box>
                    <Box style={{ width: "49%" }}>
                        <Typography variant="h5" sx={{ marginBottom: "5px" }}>
                            Device Managers
                        </Typography>
                        <Paper variant="outlined">
                            <List style={{ maxHeight: 400, overflow: "auto" }}>
                                {[...administrators.keys()].map(l =>
                                    l ? (
                                        <ListItem
                                            key={`usasdoj${l.id}`}
                                            secondaryAction={
                                                (l.id !== userId) && <IconButton onClick={() => removeAdministrator(l.id)}>
                                                    <DeleteOutline />
                                                </IconButton>
                                            }
                                        >
                                            <ListItemText
                                                primary={`${l.firstName} ${l.lastName}`}
                                                secondary={`Has access to ${administrators.get(l)?.length || 0} device${administrators.get(l)?.length === 1 ? "" : "s"}`}
                                            />
                                        </ListItem>
                                    ) : null
                                )}
                            </List>
                        </Paper>
                    </Box>
                </Box>
            </> }
        </DialogContent>
        <DialogActions>
            <Button onClick={close}>Close</Button>
        </DialogActions>
        <Dialog open={creatingInvite} onClose={() => setCreatingInvite(false)}>
            <InviteUserModal devices={devices} submitDevices={createInviteFromDevices} />
        </Dialog>
    </Box>
}

const DeviceGroup = ({ group, selectGroup, deleteGroup }: { group: IDeviceGroup, selectGroup: () => void, deleteGroup: () => void }) => {
    const deviceCount = Object.keys(group.devices).length;
    return <Card variant="outlined" sx={{ marginBottom: 1 }}>
        <CardActionArea onClick={selectGroup}>
            <CardContent sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingBottom: "12px !important" }}>
                <Box>
                    <GroupNameText groupColor={group.color}>{ group.name }</GroupNameText>
                    <Typography>{ deviceCount } autoscorers</Typography>
                </Box>
                <Box>
                    <IconButton aria-label="delete" onClick={ deleteGroup }>
                        <DeleteOutline />
                    </IconButton>
                </Box>
            </CardContent>
        </CardActionArea>
    </Card>
};

const GroupDevice = ({ group, device }: { group: IDeviceGroup, device: IDevice }) => {
    const DB = useDatabase();
    const [stripText, setStripText] = useState((device.eventStrip === undefined ? "" : device.eventStrip + 1).toString());
    
    const removeDeviceFromGroup = async (deviceId: string) => {
        if (!group?.id || !deviceId) return;
        await DB.removeDeviceFromGroup(deviceId, group.id);
    }

    const setDeviceEventGender = async (gender: "unset" | "mens" | "womens") => {
        await DB.setDeviceEventGender(device.id, gender === "unset" ? null : gender);
    }

    const setDeviceEventStrip = async (text: string) => {
        const strip = text === "" ? NaN : Number(text);
        await DB.setDeviceEventStrip(device.id, isNaN(strip) ? null : strip - 1);
    }

    const setWeapon = (weapon: Weapon | "unset") => {
        DB.setDeviceWeapon(device.id, weapon === "unset" ? null : weapon, true);
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = event.target.value;
    
        // Check if the input value consists only of digits
        if (/^\d*$/.test(newValue)) {
            setStripText(newValue);
            if (Number(newValue) > 0) setDeviceEventStrip(newValue);
        }
    };

    return <Card variant="outlined" sx={{ my: 1 }} key={`groupDevice${device.id}`}>
        <CardContent sx={{ paddingBottom: "12px !important" }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography sx={{ fontFamily: "Lexend Deca", fontSize: 18 }}>{ device.name }</Typography>
                <IconButton aria-label="delete" onClick={ () => removeDeviceFromGroup(device.id) }>
                    <DeleteOutline />
                </IconButton>
            </Box>
            <Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ marginRight: 1 }}>Gender:</Typography>
                    <TextField
                        value={device.eventGender || "unset"}
                        onChange={(e) => setDeviceEventGender(e.target.value as "unset" | "mens" | "womens")}
                        select
                        hiddenLabel
                        variant="standard"
                    >
                        <MenuItem value="unset">Unset</MenuItem>
                        <MenuItem value="mens">Men's</MenuItem>
                        <MenuItem value="womens">Women's</MenuItem>
                    </TextField>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ marginRight: 1 }}>Strip:</Typography>
                    <TextField
                        value={stripText}
                        onChange={handleChange}
                        hiddenLabel
                        variant="standard"
                        inputProps={{
                            inputMode: 'numeric', // Ensures a numeric keyboard on mobile devices
                            pattern: '[0-9]*' // Provides an extra hint for browser validation
                        }}
                        sx={{ maxWidth: 50 }}
                    />
                    <Button onClick={() => setDeviceEventStrip("")}>Clear</Button>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ marginRight: 1 }}>Weapon:</Typography>
                    <TextField
                        value={device.weapon || "unset"}
                        onChange={(e) => setWeapon(e.target.value as Weapon)}
                        select
                        hiddenLabel
                        variant="standard"
                    >
                        <MenuItem value="unset">Unset</MenuItem>
                        <MenuItem value="Sabre">Sabre</MenuItem>
                        <MenuItem value="Foil">Foil</MenuItem>
                        <MenuItem value="Epee">Epee</MenuItem>
                    </TextField>
                </Box>
            </Box>
        </CardContent>
    </Card>
}

const Devices = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const userInfo = useAppSelector(s => s.user.userInfo);
    const userLoaded = useAppSelector(s => s.user.userLoaded);

    const DB = useDatabase();
    const [devices, setDevices] = useState<Record<string, IDevice>>({});
    const [devicesLoaded, setDevicesLoaded] = useState(false);
    const [deviceGroups, setDeviceGroups] = useState<Record<string, IDeviceGroup>>({});
    const [hsMeets, setHsMeets] = useState<Record<string, IDualMeet_DB>>({});
    const [collegeMeets, setCollegeMeets] = useState<
        Record<string, IDualMeet_DB>
    >({});
    const [collegeEvents, setCollegeEvents] = useState<Record<string, ICollegeEvent>>({});
    const [allUsers, setAllUsers] = useState<Record<string, IUser>>({});

    const [creatingGroup, setCreatingGroup] = useState(false);
    const [selectingGroupEvent, setSelectingGroupEvent] = useState(false);
    const [addingDeviceToGroup, setAddingDeviceToGroup] = useState(false);

    const [selectedGroup, setSelectedGroup] = useState<IDeviceGroup | null>(null);
    const selectedEvent = selectedGroup?.event ? collegeEvents[selectedGroup.event] : null;

    const [firmwareVersions, setFirmwareVersions] = useState<string[]>([]);

    const [invitingUsers, setInvitingUsers] = useState(false);
    const [invites, setInvites] = useState<Record<string, IDeviceInvite>>({});

    const loaded = userLoaded;

    const handleDeviceGroups = (result: DBResult<Record<string, IDeviceGroup>>) => {
        if (result.status === "fail") return;
        setDeviceGroups(result.data);

        setSelectedGroup(curr => {
            if (!curr?.id) return null;
            const matchingGroup = result.data[curr?.id];
            return matchingGroup || null
        });
    };

    const handleDevices = (result: DBResult<Record<string, IDevice>>) => {
        if (result.status === "fail") return;
        setDevices(result.data);
        setDevicesLoaded(true);
    };

    const handleFirmwareVersions = (result: DBResult<string[]>) => {
        if (result.status === "fail") return;
        setFirmwareVersions(result.data);
    };

    const handleDeviceInvites = (result: DBResult<Record<string, IDeviceInvite>>) => {
        if (result.status === "fail") return;
        setInvites(result.data);
    }

    useEffect(() => {
        DB.getMeets(Division.NJSIAA).then((result: DBResult<Record<string, IDualMeet_DB>>) => {
            if (result.status === "fail") return;
            setHsMeets(result.data);
        });
        DB.getMeets(Division.NCAA).then((result: DBResult<Record<string, IDualMeet_DB>>) => {
            if (result.status === "fail") return;
            setCollegeMeets(result.data);
        });
        DB.getEvents().then((result: DBResult<Record<string, ICollegeEvent>>) => {
            if (result.status === "fail") return;
            setCollegeEvents(result.data);
        });

        DB.getFirmwareVersions(handleFirmwareVersions);

        return () => {
            DB.stopListeningFirmwareVersions(handleFirmwareVersions);
        }
    }, []);

    useEffect(() => {
        if (!userInfo?.id) return;
        DB.getDevicesForUser(userInfo.id, { listener: handleDevices });
        DB.getDeviceGroupsForUser(userInfo.id, { listener: handleDeviceGroups });
        DB.getInvitesByUser(userInfo.id, handleDeviceInvites);

        DB.getUserList().then(result => {
            if (result.status === "fail") return;
            setAllUsers(result.data);
        });

        return () => {
            DB.stopListeningDevices(handleDevices);
            DB.stopListeningDeviceGroups(handleDeviceGroups);
            // DB.stopListeningInvites(userInfo.id, handleDeviceInvites);
        }
    }, [userInfo?.id]);

    const setGroupEvent = async (eventId: string) => {
        if (!selectedGroup?.id || !eventId) return;
        await DB.setGroupEvent(selectedGroup.id, eventId, true);
        setSelectingGroupEvent(false);
    }

    const createGroup = async (name: string, color: string) => {
        await DB.createDeviceGroup(name, color);
        setCreatingGroup(false);
    }

    const deleteGroup = (id: string) => DB.deleteDeviceGroup(id);
    
    const addDeviceToGroup = async (deviceId: string) => {
        if (!selectedGroup?.id || !deviceId) return;
        await DB.addDeviceToGroup(deviceId, selectedGroup.id);
        setAddingDeviceToGroup(false);
    }

    const applyEventSettings = async () => {
        if (!selectedGroup?.id) return;
        await DB.applyGroupEventSettings(selectedGroup.id);
    }

    const prevRound = async () => {
        if (!selectedGroup?.id || selectedGroup.eventRound <= 0) return;
        await DB.setGroupRoundIdx(selectedGroup.id, selectedGroup.eventRound - 1, true);
    }

    const nextRound = async () => {
        if (!selectedGroup?.id) return;
        await DB.setGroupRoundIdx(selectedGroup.id, selectedGroup.eventRound + 1, true);
    }

    const deviceGroupsArr = useMemo(() => Object.values(deviceGroups), [deviceGroups]);
    const selectedGroupDevices = Object.keys(selectedGroup?.devices || {}).map(l => devices[l]);

    const administrators = useMemo(() => {
        const resp = new Map<IUser, string[]>();
        for (const id in devices) {
            const device = devices[id];
            if (!device.owners) continue;
            for (const ownerId in device.owners) {
                if (resp.has(allUsers[ownerId])) {
                    resp.get(allUsers[ownerId])!.push(id);
                } else {
                    resp.set(allUsers[ownerId], [id]);
                }
            }
        }
        return resp;
    }, [allUsers, devices]);

    if (!loaded) {
        return <CommonLoading />
    }

    if (!userInfo) {
        return <ErrorPage code={401} message="You must be logged in to view your devices." />
    }

    return (
        <Container sx={{ height: "100%", width: "100%" }} maxWidth="md">
            <Typography variant="h3" fontFamily="Lexend Deca" sx={{ paddingTop: "30px", textAlign: "center" }}>
                My Devices
                { (userInfo.flags & UserFlag.SuperAdmin) ? <Chip label="Admin View" color="primary" sx={{ marginLeft: 1 }} /> : null }
                <Button startIcon={<PersonAdd />} sx={{ marginLeft: 1 }} onClick={() => setInvitingUsers(true)}>Invites</Button>
            </Typography>

            <Grid container columnSpacing={fullScreen ? 0 : 2} rowSpacing={2} sx={{ margin: "20px auto", marginLeft: fullScreen ? 0 : -1, width: "100%" }}>
                <Grid item sm={12} md={6}>
                    <Paper sx={{ padding: 2 }}>
                        <Typography variant="h5" style={{ fontFamily: "Lexend Deca" }}>My Groups</Typography>
                        <Typography variant="caption" style={{ color: "#666" }}>
                            Groups allow you to control multiple autoscorers at once for the purposes of running a college event.
                        </Typography>
                        <Box sx={{ width: "100%", my: "5px", maxHeight: 250, overflowY: "auto" }}>
                            { deviceGroupsArr.length
                                ? deviceGroupsArr.map(l => <DeviceGroup
                                    key={`deviceGroup${l.id}`}
                                    group={l}
                                    selectGroup={() => setSelectedGroup(l)}
                                    deleteGroup={() => deleteGroup(l.id)}
                                />)
                                : <Typography variant="body1" sx={{ width: "100%", textAlign: "center" }}>You currently have no groups.</Typography>
                            }
                        </Box>
                        <Button onClick={() => setCreatingGroup(true)} startIcon={<Add />}>Create group</Button>
                    </Paper>
                </Grid>
                {selectedGroup && <Grid item sm={12} md={6}>
                    <Paper sx={{ padding: 2 }}>
                        <GroupNameText groupColor={selectedGroup.color}>{ selectedGroup.name }</GroupNameText>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography>Selected event: <strong>{ selectedEvent?.name || "None" }</strong></Typography>
                            <Button startIcon={<Search />} onClick={() => setSelectingGroupEvent(true)} sx={{ marginLeft: 1 }}>Select</Button>
                        </Box>
                        <Button
                            disabled={!selectedEvent}
                            startIcon={<Upload />}
                            variant="contained"
                            sx={{ margin: "4px 0" }}
                            onClick={() => applyEventSettings()}
                        >
                            Apply event settings to devices
                        </Button>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Button
                                disabled={!selectedEvent}
                                startIcon={<ArrowBack />}
                                onClick={() => prevRound()}
                            >
                                Previous Round
                            </Button>
                            <Typography>Round { selectedGroup.eventRound + 1 }</Typography>
                            <Button
                                disabled={!selectedEvent}
                                endIcon={<ArrowForward />}
                                onClick={() => nextRound()}
                            >
                                Next Round
                            </Button>
                        </Box>
                        { selectedGroupDevices.map(l => <GroupDevice key={`groupDevice${l.id}`} group={selectedGroup} device={l} />) }
                        <Button startIcon={<Add />} onClick={() => setAddingDeviceToGroup(true)}>Add device</Button>
                    </Paper>
                </Grid>}
            </Grid>
            
            <Box sx={{ marginBottom: "20px" }}>
                {Object.values(devices).map((l) => (
                    <Device
                        id={l.id}
                        key={`device${l.id}`}
                        groups={deviceGroups}
                        firmwareVersions={firmwareVersions}
                        hsMeets={hsMeets}
                        collegeMeets={collegeMeets}
                        allUsers={allUsers}
                        startInvitingOwners={() => setInvitingUsers(true)}
                    />
                ))}
            </Box>

            <Dialog
                fullScreen={fullScreen}
                maxWidth={false}
                open={selectingGroupEvent}
                onClose={() => setSelectingGroupEvent(false)}
            >
                <ChooseEvent events={collegeEvents} chooseEvent={setGroupEvent} close={() => setSelectingGroupEvent(false)} />
            </Dialog>

            <Dialog
                fullScreen={fullScreen}
                maxWidth={false}
                open={addingDeviceToGroup}
                onClose={() => setAddingDeviceToGroup(false)}
            >
                <ChooseDevice devices={devices} chooseDevice={addDeviceToGroup} close={() => setAddingDeviceToGroup(false)} />
            </Dialog>

            <Dialog open={creatingGroup} onClose={() => setCreatingGroup(false)}>
                <CreateGroupModal createGroup={createGroup} close={() => setCreatingGroup(false)} />
            </Dialog>

            <Dialog open={invitingUsers} onClose={() => setInvitingUsers(false)}>
                <InviteListModal invites={invites} administrators={administrators} devices={devices} close={() => setInvitingUsers(false)} />
            </Dialog>
        </Container>
    );
};

export default Devices;
