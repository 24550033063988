import * as ReactDOM from "react-dom";
import { Outlet, RouterProvider, createBrowserRouter, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { Provider } from "react-redux";
import fire from "./utils/fire";
import store, { resetState, setUserInfo, useAppDispatch } from "./utils/store";
import Navigation from "./components/Navigation";
import Home from "./pages/Home";
import Viewer from "./pages/Viewer";
import Login from "./pages/Login";
import useDatabase from "./hooks/useDatabase";
import { IUser } from "./utils/types";
import { Auth, getAuth } from "firebase/auth";
import { useEffect } from "react";
import Devices from "./pages/Devices/Devices";
import ErrorPage from "./pages/Error/Error";
import Logs from "./pages/Logs/Logs";

const Root = () => {
    const DB = useDatabase();
    const location = useLocation();

    const dispatch = useAppDispatch();

    const authListener = (auth: Auth) => {
        auth.onAuthStateChanged(async user => {
            if (user && user !== null) {
                DB.getCurrentUserInfo(async userResult => {
                    if (userResult.status === "fail") return;
                    dispatch(setUserInfo(userResult.data));
                });
            } else if (user === null) {
                console.log("resetting state");
                dispatch(resetState());
            }
        })
    }

    useEffect(() => {
        const auth = getAuth();
        authListener(auth);
    }, []);

    if (location.pathname.includes("/logs/")) {
        return <Outlet />
    }

    return <>
        <Navigation />
        <Box sx={{ height: "calc(100vh - 65px)", marginTop: "65px" }}>
            <Outlet />
        </Box>
    </>
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage code={404} message="The page you are looking for could not be found." />,
        children: [
            {
                path: "/",
                element: <Home />
            },
            {
                path: "/devices",
                element: <Devices />
            },
            {
                path: "/login",
                element: <Login />
            },
            {
                path: "/viewer/:id",
                element: <Viewer />
            },
            {
                path: "/logs/:id",
                element: <Logs />
            },
            {
                path: "*",
                element: <ErrorPage code={404} message="The page you are looking for could not be found." />
            }
        ]
    }
]);

const App = () => {
    fire;
    return <RouterProvider router={router} />
}

ReactDOM.render(
    <Provider store={ store }>
        <App />
    </Provider>,
    document.getElementById("root")
);